
















































































.chat-form {
  .row {
    .textarea-col {
      .textarea {
        .v-input__slot {
          padding-top: 4px;
          padding-bottom: 10px;
          ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
          ::-webkit-scrollbar-thumb {
            background: #bdbdbd;
            border-radius: 10px;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: #999999;
          }
          ::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 10px;
          }
          textarea {
            max-height: 130px;
            overflow-y: auto;
          }
        }
        .v-text-field__details {
          display: none;
        }
      }
    }
    .btn-col {
      margin-top: -10px;
      .btn {
        height: 56px;
        width: 56px;
        border-radius: 50%;
      }
    }
  }
}
