


































































































































































































































.virtual-agent-wrapper {
  max-height: calc(100vh - 64px - 70px);
  .messages {
    flex: 100 100 auto;
    overflow: auto;
  }
  .chat-form {
    flex: 0 0 auto;
  }
}
