







































































.message {
  .message-content {
    position: relative;
    display: inline-block;
    margin-bottom: 5px;

    padding: 10px 10px 10px 12px;
    border-radius: 20px;
    white-space: pre-wrap;
    width: fit-content;
    &.first {
      position: relative;
      z-index: 1;
      &::after {
        display: block;
        position: absolute;
        top: 0px;
        content: '';
        z-index: 0;
        width: 0;
        height: 0;
      }
    }

    &.own {
      color: white;
      &.first {
        &::after {
          right: 0;
          border-top: 18px solid var(--v-primary-base);
          border-left: 18px solid transparent;
        }
      }
    }
    &:not(.own) {
      &.first {
        &::after {
          left: 0;
          border-top: 18px solid var(--v-background-darken1);
          border-right: 18px solid transparent;
        }
      }
    }
  }
  .message-data {
    margin-top: -10px;
  }
  .widget-content {
    margin-bottom: 5px;

    padding: 10px 10px 10px 12px;
    border-radius: 20px;
    white-space: pre-wrap;
    width: fit-content;
  }
}
