




























.message-row {
  display: flex;
}
