








































































































.messages {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
}
